import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Namco Customer Story - Rotaready"
          description="Since getting started with Rotaready, Namco have been able to better deploy over 85 hours every week and achieved an ROI of 687%. Here's how..."
          url="customer-stories/leisure-namco"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Namco"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Leisure" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    alt="Namco logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Since getting started with Rotaready, Namco have been able to better deploy over 85 hours every week and achieved an ROI of 687%. Here's how..." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="23" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="600+" />
              <StatSubtitle text="staff" />
            </StatItem>

            <StatItem>
              <StatHeadline text="687%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="86 hours" />
              <StatSubtitle text="better deployed each week" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Namco" />
              </Content>

              <Content>
                <TextBlock text="Namco UK Limited is part of the Bandai Namco Group, founded in Japan in 1955. Its family entertainment venues exist to provide fun & inspiration for people of all ages with the likes of ten-pin bowling, amusements, dodgems and more recently, escape rooms, climbing walls and adventure golf. All its venues are complemented with substantial food and beverage offerings making them highly visited hospitality attractions." />
              </Content>

              <Content>
                <TextBlock text="As the business expanded, its approach to staff scheduling became more and more inconsistent. Some venues were using old school spreadsheets and others had legacy rota software in place." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Streamlining staff scheduling" />
              </Content>

              <Content>
                <TextBlock text="The Namco team wanted an approach to staff scheduling that would work across the diverse business. A solution that was easy to use, cost effective, came with great customer support and was flexible, yet off the shelf. Neither of their existing approaches met these criteria. The spreadsheets were time consuming and left a lot of room for error; whilst sparse product updates and lack of support from the incumbent rota software provider had become a big frustration for the team at Namco." />
              </Content>

              <Content>
                <TextBlock text="So in January 2020, they set about their search for a new, superior staff scheduling solution; one that would be a better fit for the entire business. After an extensive search the Namco team settled on Rotaready; a solution from experts in the Hospitality and Leisure industry that met all of their criteria." />
              </Content>

              <Content>
                <Blockquote
                  text="We'd looked at a number of staff scheduling solutions, but Rotaready was a clear winner for us. Not only did it tick all of the boxes in terms of functionality, we loved that it was a solution tailored to our industry. That gave us a lot of confidence that it was the right solution for us!"
                  attribution="John Wark, Compliance Manager"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Implementation & integrations" />
              </Content>

              <Content>
                <TextBlock text="Rotaready worked closely with the team at Namco to understand their business needs and implemented Rotaready across all sites, in just over two weeks. Part of the roll out included an integration with Namco's till system." />
              </Content>

              <Content>
                <TextBlock text="With this integration the team at Namco has real time visibility over costs and wage spend. Sales figures are pulled directly from the EPOS solution into Rotaready; automatically populating key tools and reports with revenue data." />
              </Content>

              <Content>
                <Blockquote
                  text="Rotaready took care of everything when it came to the integration with our till system. We told them what we wanted and it happened - it was an entirely painless process."
                  attribution="John Wark, Compliance Manager"
                />
              </Content>

              <Content>
                <TextBlock text="Implementing Rotaready across the business has enabled Namco to streamline its approach to both staff scheduling and cost control; helping managers to make more commercially aligned scheduling decisions as a result." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Effortless holiday management" />
              </Content>

              <Content>
                <TextBlock text="The business has also benefited from having greater visibility over staff holiday. Employees use the Rotaready app to request holiday, making holiday management easier than ever for managers." />
              </Content>

              <Content>
                <Blockquote
                  text="Before Rotaready, holiday management was completely manual, making monitoring employee holidays and calculating accrual a real headache for us. Having everything under one system has made things so much easier - holiday management is more accurate than ever and we're saving both time and money as a result."
                  attribution="John Wark, Compliance Manager"
                />
              </Content>
            </Section>

            <Section>
              <PopQuote text="A combination of all of these factors has saved a huge amount of time for the team at Namco, helping them better deploy over 85 hours every week. They've also seen cost savings as a direct result of implementing Rotaready, achieveing a return on investment of 687%." />
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Crazy golf at a Namco venue"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="What's next?" />
              </Content>

              <Content>
                <TextBlock text="Rotaready's customer success team are continuing to work closely with Namco to ensure they're leveraging all the benefits of the platform, while feeding back to the engineering team to ensure the product remains as customer-centric as possible." />
              </Content>

              <Content>
                <TextBlock text="Next on the agenda is enabling Rotaready's integration with Namco's training solution. This will automatically create employee profiles within their training system, and in real-time. Any changes made to employee information within Rotaready, such as job role, date of birth or leavers, will also be automatically updated within the training system." />
              </Content>

              <Content>
                <TextBlock text="Removing double entry of data will both free up time for the team at Namco and ensure consistent records across solutions." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Dodgems at a Namco venue"
              />
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_namco"
                label="rotaready.com/customer-stories/leisure-namco"
                postTo="marketing"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Namco.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/header-namco.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/logo-namco.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 60, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/inline-namco1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/inline-namco2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
